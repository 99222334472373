var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden left-side lg:tw-flex",attrs:{"sm":"12","lg":"4"}},[_c('OnboardingLeft')],1),_c('v-col',{staticClass:"right-side",attrs:{"sm":"12","lg":"8"}},[_c('v-col',{staticClass:"right-side-body scroll",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6 img-logo",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/logos/blue.png"),"alt":"logo"}}),_c('p',{staticClass:"welcome tw-mt-0"},[_vm._v("Brand Identity")]),_c('p',{staticClass:"admin tw-mt-2"},[_vm._v(" Tell us a few things about your company. ")]),_c('v-row',{staticClass:"upload-div"},[_c('v-col',{attrs:{"lg":"6","sm":"6"}},[_c('p',{staticClass:"upload-div-text tw-pb-3"},[_vm._v("Company Logo")]),_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.color,"indeterminate":""}}):_vm._e(),(_vm.organizationData.companyLogo)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.organizationData.companyLogo,"alt":""}}):(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("@/assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('div',{staticClass:"pl-3 convert"},[_c('v-col',{staticClass:"mb-md-1",attrs:{"sm":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Company's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Company's Name")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"Nomadicpod","type":"text","color":"#004aad","disabled":"","hide-details":""},model:{value:(_vm.organizationData.companyName),callback:function ($$v) {_vm.$set(_vm.organizationData, "companyName", $$v)},expression:"organizationData.companyName"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('v-col',{staticClass:"mb-md-1",attrs:{"lg":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Company's Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Company's domain for this application (optional)")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"nomadicpod.com","solo":"","hide-details":"","type":"url","required":""},model:{value:(_vm.organizationData.website),callback:function ($$v) {_vm.$set(_vm.organizationData, "website", $$v)},expression:"organizationData.website"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"pl-6"},[_c('v-col',{staticClass:"mt-md-6",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Company Vision","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Vision (optional)")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-textarea',{attrs:{"placeholder":"What is Your Company's Vision?","solo":"","hide-details":"","type":"text","required":"","height":"99"},model:{value:(_vm.organizationData.visionStatement),callback:function ($$v) {_vm.$set(_vm.organizationData, "visionStatement", $$v)},expression:"organizationData.visionStatement"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('v-col',{staticClass:"mt-md-6",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Company Mission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Mission Statement (optional)")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-textarea',{attrs:{"placeholder":"What is Your Company's Mission Statement?","solo":"","hide-details":"","type":"text","required":"","height":"99"},model:{value:(_vm.organizationData.missionStatement),callback:function ($$v) {_vm.$set(_vm.organizationData, "missionStatement", $$v)},expression:"organizationData.missionStatement"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('div',{staticClass:"btn-div"},[_c('button',{staticClass:"btn-div-text",attrs:{"disabled":!_vm.organizationData.companyLogo},on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_c('span',[_vm._v("Next")]),_c('img',{staticClass:"btn-div-img",attrs:{"src":require("@/assets/arrow_forward.svg"),"alt":""}})])])],1)],1),_c('modal',{attrs:{"dialog":_vm.dialog},on:{"close":_vm.toggleModal,"changeTitle":function($event){return _vm.ChangeT($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }